import { User } from '@/types/AccountServiceType'
import { Client, UserClientManagementModel } from '@/types/ClientServiceType'
import { BaseSearchOption, PagedListModel } from '@/types/utilTypes'
import axios from 'axios'
import { ILoggingService } from './LoggingService'

export default class ClientService {
    private readonly _logging: ILoggingService
    private readonly baseUrl = '/Client'

    constructor(logging: ILoggingService) {
        this._logging = logging
    }

    public async upsertClient(form: Client) : Promise<void> {
        const url = `${this.baseUrl}/UpsertClient/`
        try {
            await axios.post(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async deleteClient(form: Client) : Promise<void> {
        const url = `${this.baseUrl}/DeleteClient/`
        try {
            await axios.post(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async getUsersNotInClient(search: BaseSearchOption): Promise<PagedListModel<User>> {
        const url = `${this.baseUrl}/GetUsersNotInClient/`
        try {
            const resp = await axios.post(url, search)
            return resp.data
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async addUsersToClient(form: UserClientManagementModel): Promise<void> {
        const url = `${this.baseUrl}/AddUsersToClient/`
        try {
            await axios.post(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async removeUsersInClient(form: UserClientManagementModel): Promise<void> {
        const url = `${this.baseUrl}/RemoveUsersInClient/`
        try {
            await axios.post(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }
}