export const dateFormat = 'DD-MMM-YYYY'
export const timeFormat = 'hh:mm A'
export const dateTimeFormat = 'DD-MMM-YYYY hh:mm A'
export const dateTimeSecondsFormat = 'DD-MMM-YYYY hh:mm:ss A'
export const dateTimeAlphaFormat = 'MMM DD, YYYY hh:mm A'
export const dateAlphaFormat = 'MMM DD, YYYY'
export const isoDateFormat = 'YYYY-MM-DD'

export const colorsOrdered = [
    'red', 'red lighten-3', 'red lighten-2', 'red lighten-1',
    'red darken-1', 'red darken-2', 'red darken-3', 'red darken-4',
    'red accent-2', 'red accent-3', 'red accent-4',
    'pink', 'pink lighten-3', 'pink lighten-2', 'pink lighten-1',
    'pink darken-1', 'pink darken-2', 'pink darken-3', 'pink darken-4',
    'pink accent-2', 'pink accent-3', 'pink accent-4',
    'purple', 'purple lighten-3', 'purple lighten-2', 'purple lighten-1',
    'purple darken-1', 'purple darken-2', 'purple darken-3', 'purple darken-4',
    'purple accent-2', 'purple accent-3', 'purple accent-4',
    'deep-purple ', 'deep-purple lighten-3', 'deep-purple lighten-2', 'deep-purple lighten-1',
    'deep-purple darken-1', 'deep-purple darken-2', 'deep-purple darken-3', 'deep-purple darken-4',
    'deep-purple accent-1', 'deep-purple accent-2', 'deep-purple accent-3', 'deep-purple accent-4',
    'indigo', 'indigo lighten-3', 'indigo lighten-2', 'indigo lighten-1',
    'indigo darken-1', 'indigo darken-2', 'indigo darken-3', 'indigo darken-4',
    'indigo accent-2', 'indigo accent-3', 'indigo accent-4',
    'blue', 'blue lighten-3', 'blue lighten-2', 'blue lighten-1',
    'blue darken-1', 'blue darken-2', 'blue darken-3', 'blue darken-4',
    'blue accent-2', 'blue accent-3', 'blue accent-4',
    'light-blue', 'light-blue lighten-4', 'light-blue lighten-3', 'light-blue lighten-2', 'light-blue lighten-1',
    'light-blue darken-1', 'light-blue darken-2', 'light-blue darken-3', 'light-blue darken-4',
    'light-blue accent-2', 'light-blue accent-3', 'light-blue accent-4',
    'cyan', 'cyan lighten-3', 'cyan lighten-2', 'cyan lighten-1',
    'cyan darken-1', 'cyan darken-2', 'cyan darken-3', 'cyan darken-4',
    'cyan accent-2', 'cyan accent-3', 'cyan accent-4',
    'teal', 'teal lighten-3', 'teal lighten-2', 'teal lighten-1',
    'teal darken-1', 'teal darken-2', 'teal darken-3', 'teal darken-4',
    'teal accent-2', 'teal accent-3', 'teal accent-4',
    'green', 'green lighten-3', 'green lighten-2', 'green lighten-1',
    'green darken-1', 'green darken-2', 'green darken-3', 'green darken-4',
    'green accent-2', 'green accent-3', 'green accent-4',
    'light-green', 'light-green lighten-3', 'light-green lighten-2', 'light-green lighten-1',
    'light-green darken-1', 'light-green darken-2', 'light-green darken-3', 'light-green darken-4',
    'light-green accent-2', 'light-green accent-3', 'light-green accent-4',
    'lime', 'lime lighten-3', 'lime lighten-2', 'lime lighten-1',
    'lime darken-1', 'lime darken-2', 'lime darken-3', 'lime darken-4',
    'lime accent-2', 'lime accent-3', 'lime accent-4',
    'yellow darken-1', 'yellow darken-2', 'yellow darken-3', 'yellow darken-4',
    'yellow accent-2', 'yellow accent-3', 'yellow accent-4',
    'amber', 'amber lighten-3', 'amber lighten-2', 'amber lighten-1',
    'amber darken-1', 'amber darken-2', 'amber darken-3', 'amber darken-4',
    'amber accent-2', 'amber accent-3', 'amber accent-4',
    'orange', 'orange lighten-3', 'orange lighten-2', 'orange lighten-1',
    'orange darken-1', 'orange darken-2', 'orange darken-3', 'orange darken-4',
    'orange accent-2', 'orange accent-3', 'orange accent-4',
    'deep-orange', 'deep-orange lighten-3', 'deep-orange lighten-2', 'deep-orange lighten-1',
    'deep-orange darken-1', 'deep-orange darken-2', 'deep-orange darken-3', 'deep-orange darken-4',
    'deep-orange accent-2', 'deep-orange accent-3', 'deep-orange accent-4',
    'blue-grey', 'blue-grey lighten-3', 'blue-grey lighten-2', 'blue-grey lighten-1',
    'blue-grey darken-1', 'blue-grey darken-2', 'blue-grey darken-3', 'blue-grey darken-4'
]

export const genericErrorMessage = 'We\'re sorry, but we can\'t process your request at this time. Please try again later.'

export const authPermissions = {
    canExportAll: 'export',
    canAccessAllQuantumCharts: 'quantumCharts',
    canUseAllImport: 'import',
    canAccessAllRelatedSettings: 'settings',
    canUseUpload: 'upload',
    canManageUsers: 'userManagement',
    canManageClients: 'clientManagement',
    canAccessAccounting: 'accountingPage'
}

export const currentPivotKeyPrefix = 'current_pivot_setting_c95410a6755x'