import { BaseThemeSetting } from '@/types/ThemeSettingsType'
import { ILoggingService } from './LoggingService'
import axios from 'axios'

export interface IThemeSettingsService {
    saveTheme(form: FormData): Promise<void>
    getTheme(): Promise<BaseThemeSetting>
}

export default class ThemeSettingsService implements IThemeSettingsService {
    private readonly _logging: ILoggingService
    private readonly baseUrl = '/ThemeSettings'

    constructor(logging: ILoggingService) {
        this._logging = logging
    }

    public async saveTheme(form: FormData): Promise<void> {
        const url = `${this.baseUrl}/SaveTheme/`
        try {
            await axios.post(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }
    
    public async getTheme(): Promise<BaseThemeSetting> {
        const url = `${this.baseUrl}/GetTheme/`
        try {
            const response = await axios.get(url)
            return response.data
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }
}