import { ILoggingService } from './LoggingService'
import axios from 'axios'
import {StackGmapIconSetting, GmapRecordSetting, StackGmapIconSettingForm, StackGmapIconSettingResponse } from '@/types/StackGmapServiceType'
import { Page } from '@/types/enums'

export default class StackGmapService {
    private readonly _logging: ILoggingService
    private readonly baseUrl = '/GmapSetting'

    constructor(logging: ILoggingService) {
        this._logging = logging
    }
    
    public async getGmapFieldValues(module: string, alias: string): Promise<string[]> {
        const url = `${this.baseUrl}/GetGmapFieldValues/${module}/${alias}`
        try {
            return (await axios.get<{result: string[]}>(url)).data.result
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async saveIconSetting(form: StackGmapIconSettingForm): Promise<void> {
        const url = `${this.baseUrl}/UpsertGmapIconSetting/`
        try {
            await axios.post(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async getIconSetting(page = Page.GLOBAL) : Promise<StackGmapIconSetting> {
        const url = `${this.baseUrl}/GetGmapIconSetting/${page}/`
        try {
            const result: StackGmapIconSetting = {
                page: null,
                iconField: null,
                backgroundField: null,
                iconSettingMetadata: {},
                backgroundSettingMetadata: {}
            }
            
            const response = (await axios.get<StackGmapIconSettingResponse>(url)).data
            result.page = response.page
            result.backgroundField = response.backgroundField
            result.iconField = response.iconField

            response.iconSettingMetadata.forEach( iconSettingMeta => {
                result.iconSettingMetadata[iconSettingMeta.iconFieldValue] = iconSettingMeta
            })

            response.backgroundSettingMetadata.forEach( backgroundSettingMeta => {
                result.backgroundSettingMetadata[backgroundSettingMeta.backgroundFieldValue] = backgroundSettingMeta
            })
            
            return result
            
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
        
    }

    public async getGmapRecordSetting(page: Page) : Promise<GmapRecordSetting>{
        const url = `${this.baseUrl}/GetGmapPopupInfoSetting/${page}`
        try {
            return (await axios.get<GmapRecordSetting>(url)).data
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async saveGmapRecordSetting(form: GmapRecordSetting) : Promise<void> {
        const url = `${this.baseUrl}/UpsertGmapPopupInfoSetting/`
        try {
            await axios.post<GmapRecordSetting>(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

}