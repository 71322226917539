import { accountService } from '@/services'
import { OtherThemeSetting, ThemeEmailInfoSettings } from '@/types/ThemeSettingsType'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            username: null,
            firstName: '',
            lastName: '',
            title: '',
            initial: '',
            email: null,
            permissions: {},
            clients: [],
            defaultClient: ''
        },
        authenticated: false,
        loading: false,
        loadingText: '',
        setting:{theme:{},jumpLinks:[],themeOther:{}},
        forbidden: false,
        appKey: Date.now(),
        cssProps: {
            '--border-radius': '28px',
            '--font-family': 'Roboto',
        } as Record<string, string> | null,
        help: {
            email:'',
            cc:'',
            subject:'',
            url:'',
            mode:'off',
        } as ThemeEmailInfoSettings,
        suggestion: {
            email:'',
            cc:'',
            subject:'',
            url:'',
            mode:'off',
        } as ThemeEmailInfoSettings
    },
    mutations: {
        setUser(state, user) {
            if (user == null) {
                state.user = {
                    username: null,
                    firstName: '',
                    lastName: '',
                    title: '',
                    initial: '',
                    email: null,
                    permissions: {},
                    clients: [],
                    defaultClient: ''
                }
            } else {
                const tempUser = user

                tempUser.permissions = user.permissions.reduce((prev: Record<string, boolean>, curr: string) => {
                    prev[curr] = true
                    return prev
                }, {})
                state.user = tempUser
            }
        },
        setAuthenticated(state, payload) {
            state.authenticated = payload
        },
        setLoading(state, payload = false) {
            state.loading = payload
        },
        setLoadingText(state, payload = '') {
            state.loadingText = payload
        },
        setSetting(state,payload) {
            state.setting = payload
        },
        setIsForbidden(state, payload) { 
            state.forbidden = payload
        },
        setAppKey(state, payload) {
            state.appKey = payload
        },
        setCssProps(state, payload) {
            state.cssProps = payload
        },
        setHelp(state, payload) {
            state.help = payload
        },
        setSuggestion(state, payload) {
            state.suggestion = payload
        }
    },
    actions: {
        async logout({ commit }, redirect: boolean) {
            console.log('Logging out...')
            try {
                commit('setLoading', true)
                try {
                    const logout = await accountService.Logout()
                    commit('setUser', null)
                    commit('setAuthenticated', false)
                    if(redirect) {
                        const postLoginUri =  `${window.location.origin}/?domain_hint=none`
                        window.location.href = `${logout.headers.location}?post_logout_redirect_uri=${encodeURIComponent(postLoginUri)}`
                    }
                } finally {
                    commit('setLoading', false)
                }

            } catch (e) {
                console.log('logout error...', e)
            }

            console.log('logout succesfully...')
        },
        async getUserInfo({ dispatch, commit }) {
            try {
                this.commit('setLoading', true)
                const user = await accountService.GetUser()

                dispatch('updateUser', user)
            } catch (err) {
                console.log(err)
                commit('setUser', null)
                commit('setAuthenticated', false)
            } finally {
                this.commit('setLoading', false)
            }
        },
        updateUser({ commit }, user: any) {
            commit('setAuthenticated', true)
            commit('setUser', user)
        },
        showPageLoading({ commit }, show = true, loadingText = 'Loading please wait...') {
            commit('setLoadingText', loadingText)
            commit('setLoading', show)
        },
        forbidUser({ commit }, forbid = true) {
            commit('setIsForbidden', forbid)
        },
        generateNewKey({ commit}) {
            commit('setAppKey', Date.now())
        },
        updateOtherTheme({ commit }, otherTheme: OtherThemeSetting) {
            commit('setCssProps', {
                '--border-radius': `${otherTheme.radius}px`,
                '--font-family': otherTheme.font,
            })

            if(otherTheme.help) {
                commit('setHelp', otherTheme.help)
            }


            if(otherTheme.suggestion)  {
                commit('setSuggestion', otherTheme.suggestion)
            }
        }
    },
    getters: {
        isAuthenticated: state => state.authenticated,
        isForbidden: state => state.forbidden,
        user: state => state.user,
        appKey: state=> state.appKey,
        cssProps: state => state.cssProps,
        help: state => state.help,
        suggestion: state => state.suggestion
    },
    modules: {
    }
})
