import { User } from '@/types/AccountServiceType'
import axios, { AxiosResponse } from 'axios'
import { IApiService } from './ApiService'
import { ILoggingService } from './LoggingService'

export interface IAccountService {
    Logout(): Promise<AxiosResponse>
    GetUser(): Promise<User>
    Redirecter(redirectUrl: string): Promise<string>
    SwitchClient(clientId: string): Promise<void> 
}

export default class AccountService implements IAccountService {
    private readonly _logging: ILoggingService
    private readonly _api: IApiService
    private baseUrl = '/Account'

    constructor(api: IApiService, logging: ILoggingService) {
        this._logging = logging
        this._api = api
    }


    public async Logout(): Promise<AxiosResponse> {
        const url = '/signout-oidc'
        const response = await this._api.get(url)
        return response
    }

    public async GetUser(): Promise<any> {
        const url = `${this.baseUrl}/GetUser`
        const response = await this._api.get(url)
        return response.data
    }

    public async SwitchClient(clientId: string): Promise<void> {
        await this._api.get(`${this.baseUrl}/SwitchClient/${clientId}`)
    }

    public async Redirecter(redirectUrl: string): Promise<string> {
        const url = `${this.baseUrl}/Redirecter`

        try {
            await this._api.get(url, { params: { url: redirectUrl}}) as AxiosResponse
        } catch (error) {
            if(axios.isAxiosError(error) && error.response?.status == 401 && error.response?.headers.location) {
                return error.response.headers.location
            }
            
            this._logging.LogError((error as Error).message, (error as Error).stack)
            
        }

        return ''
    } 
}
