





















import Vue from 'vue'

export default Vue.extend({
    methods: {
        onLogout() {
            this.$store.dispatch('logout', true)
        },
    }
})
