import AccountService, { IAccountService } from './AccountService'
import ApiService from './ApiService'
import CardSettingService from './CardSettingService'
import ClientService from './ClientService'
import JumpLinksService from './JumpLinksService'
import LocationService from './LocationService'
import LoggingService from './LoggingService'
import QuantumChartReportService from './QuantumChartReportService'
import ReportService from './ReportService'
import StackGmapService from './StackGmapService'
import ThemeSettingsService from './ThemeSettingsService'
import UserService from './UserService'

const loggingService = new LoggingService()
const apiService = new ApiService(loggingService)
const locationService = new LocationService(loggingService)
const stackGmapService = new StackGmapService(loggingService)
const cardSettingService = new CardSettingService(loggingService)
const themeSettingsService = new ThemeSettingsService(loggingService)
const jumpLinksService = new JumpLinksService(loggingService)
const reportService = new ReportService(loggingService)
const quantumChartReportService = new QuantumChartReportService(loggingService)
const clientService = new ClientService(loggingService)


const userService = new UserService(apiService, loggingService)
const accountService = new AccountService(apiService, loggingService) as IAccountService

export {
    accountService,
    cardSettingService,
    clientService,
    jumpLinksService,
    locationService,
    quantumChartReportService,
    reportService,
    stackGmapService,
    themeSettingsService,
    userService
}

