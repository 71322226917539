import { ILoggingService } from './LoggingService'
import axios from 'axios'
import { CardSetting } from '@/types/CardSettingServiceType'
import { Page } from '@/types/enums'

export default class StackGmapService {
    private readonly _logging: ILoggingService
    private readonly baseUrl = '/CardSetting'

    constructor(logging: ILoggingService) {
        this._logging = logging
    }
    
    public async getCardSettings(page: Page): Promise<CardSetting[]> {
        const url = `${this.baseUrl}/GetCardsSettings/${page}`
        try {
            return (await axios.get<{ result: CardSetting[]}>(url)).data.result
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }

    }

    public async saveCardSetting(form: CardSetting): Promise<void> {
        const url = `${this.baseUrl}/UpsertCardSetting/`
        try {
            await axios.post(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }
}