import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { themeSettingsService } from '@/services'

Vue.use(Vuetify)

export default async function generateVuetify() : Promise<Vuetify> {
    let theme = {
        primary: '#4E8FCA',
        secondary: '#A0D1FF',
    }

    
    const customTheme = (await themeSettingsService.getTheme()).theme
    if(customTheme) {
        theme = customTheme
    }

    return new Vuetify({
        theme: {
            themes: {
                light: theme
            }
        }
    })
}
