




import { themeSettingsService } from '@/services'
import Vue from 'vue'

export default Vue.extend({
    async mounted() {
        const retrievedTheme = await themeSettingsService.getTheme()

        if(retrievedTheme.theme) {
            this.$vuetify.theme.themes.light = retrievedTheme.theme    
        }

        if(retrievedTheme.otherTheme) {
            await this.$store.dispatch('updateOtherTheme', retrievedTheme.otherTheme)
        }
    }
})
