import { Loader } from '@googlemaps/js-api-loader'
import '@mdi/font/css/materialdesignicons.css'
import axios, { AxiosError } from 'axios'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import Notifications from 'vue-notification'
import App from './App.vue'
import generateVuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueCookies)

import './filters'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import './assets/global.scss'
import { accountService } from './services'
Vue.use(VueAxios, axios)
Vue.use(Notifications)
dayjs.extend(customParseFormat)


async function renderApp() {
    // @ts-ignore
    axios.defaults.mode = 'cors'
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.withCredentials = true

    Vue.config.productionTip = false
    const vuetify = await generateVuetify()

    const loader = new Loader({
        apiKey: process.env.VUE_APP_MAPS_API_KEY ?? '',
        version: '3.54',
        libraries: ['core', 'places', 'drawing']
    })

    // await loader.importLibrary('core')
    // await loader.importLibrary('places')
    // await loader.importLibrary('drawing')
    await loader.load()


    const vm = new Vue({
        store,
        vuetify,
        router,
        render: h => h(App)
    }).$mount('#app')


    axios.interceptors.response.use(function(response) {
        return response
    }, async function(error: AxiosError) {

        if(error.response?.status == 401 && !(error?.config?.url ?? '').includes('Redirecter')) {
            await vm.$store.dispatch('logout', false)
            const redirectUrl = await accountService.Redirecter(window.location.href)
            // Change this afterwards to Microsoft, when we need to login via SSO
            const domainHint = router.currentRoute.query.domain_hint || 'none'
            
            if(redirectUrl) {
                window.location.href = `${redirectUrl}&domain_hint=${domainHint}`
            } else {
                await vm.$store.dispatch('forbidUser', true)
            }
        }

        if(error.response?.status == 403) { 
            await vm.$store.dispatch('forbidUser', true)
        }
        
        return Promise.reject(error)
    })

}

renderApp()
