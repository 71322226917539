import Vue from 'vue'
import { colorsOrdered } from '@/constants'
import { shuffle } from '@/utils'

export default Vue.extend({
    computed: {
        settings(): any {
            return this.$store.state.setting
        },
        user(): any {
            return this.$store.state.user
        },
        isAuthenticated(): any {
            return this.$store.getters.isAuthenticated
        },
        loading(): any {
            return this.$store.state.loading
        },
        loadingText(): string {
            return this.$store.state.loadingText
        },
        colors(): any[] {
            const c = window.localStorage.getItem('colors')

            if (c) {
                const colors = JSON.parse(c)

                return colors
            }

            return colorsOrdered
        },
        darkTheme() {
            return false
        },
        apiURL() {
            return process.env.VUE_APP_API_URL
        },
        isForbidden() {
            return this.$store.getters.isForbidden && this.$router.currentRoute.name !== 'notFound'
        }
    },
    methods: {
        getCode(user: any): number {
            if (user === undefined || user == null)
                user = this.user

            if (!user.username)
                return 0

            const code = (user.username.charCodeAt(0) +
                user.firstName.charCodeAt(2) +
                user.lastName.charCodeAt(2)) - 120

            if (isNaN(code) || code < 0)
                return 0

            if (code > (this.colors.length - 1)) {
                return this.colors.length - 1
            }
            else {
                return code
            }
        },
        notifySuccess(msg: string) {
            this.$notify({
                group: 'notify-success',
                title: '<h1>Success</h1>',
                type: 'success',
                duration: 5000,
                text: `<span class="subtitle-1">${msg}</span>`
            })
        },
        notifyError(msg: string) {
            this.$notify({
                group: 'notify-error',
                title: '<h1>Error</h1>',
                type: 'error',
                duration: 5000,
                text: `<span class="subtitle-1">${msg}</span>`
            })
        },
        shuffleColors() {
            if (window.localStorage.getItem('colors') == undefined) {
                const colors = shuffle(colorsOrdered)

                window.localStorage.setItem('colors', JSON.stringify(colors))
            }
        },
        showPageLoading(show: boolean): void {
            this.$store.dispatch('showPageLoading', show)
        }
    }
})
