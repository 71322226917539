import { JumpLink } from '@/types/JumpLinksType'
import { ILoggingService } from './LoggingService'
import axios from 'axios'

export interface IJumpLinksService {
    saveJumpLinks(form: JumpLink[]): Promise<void>
    getJumpLinks(location: string): Promise<JumpLink[]>
}

export default class JumpLinksService implements IJumpLinksService {
    private readonly _logging: ILoggingService
    private readonly baseUrl = '/JumpLink'

    constructor(logging: ILoggingService) {
        this._logging = logging
    }

    public async saveJumpLinks(form: JumpLink[]): Promise<void> {
        const url = `${this.baseUrl}/SaveJumpLinks/`
        try {
            await axios.post(url, form)
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }
    
    public async getJumpLinks(location = ''): Promise<JumpLink[]> {
        const url = `${this.baseUrl}/GetJumpLinks/${location}`
        try {
            const response = await axios.get(url)
            return response.data.result
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }
}