import axios from 'axios'
import { ILoggingService } from './LoggingService'

export interface IApiService {
    post(url: string, payload: any, header?: any): Promise<any>
    put(url: string, payload: any, header?: any): Promise<any>
    get(url: string, params?: any): Promise<any>
    delete(url: string): Promise<any>
}

export default class ApiService implements IApiService {

    private readonly _logging: ILoggingService

    constructor(logging: ILoggingService) {
        this._logging = logging
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async post(url: string, payload: any, header?: any): Promise<any> {
        try {
            return await axios.post(url, payload, header)
        } catch (error) {
            this._logging.LogError(url, error)
            throw error
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async put(url: string, payload: any, header?: any): Promise<any> {
        try {
            return await axios.put(url, payload, header)
        } catch (error) {
            this._logging.LogError(url, error)
            throw error
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async get(url: string, params?: any): Promise<any> {
        try {
            return await axios.get(url, params)
        } catch (error) {
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async delete(url: string): Promise<any> {
        try {
            return await axios.delete(url)
        } catch (error) {
            this._logging.LogError(url, error)
            throw error
        }
    }
}
