






























































































































import { colorsOrdered } from '@/constants'
import { routes } from '@/router/index'

import { accountService } from '@/services'
import { isFeatureAccessible } from '@/utils'
import Vue from 'vue'
import { RouteConfig } from 'vue-router'
const rootRoutes = routes[0].children as RouteConfig[]

export default Vue.extend({
    name: 'navbar',
    data() {
        return {
            drawer: false,
            group: rootRoutes.findIndex(_=>_.path ===this.$route.path) ?? -1,
            routes: [] as any[],
            logo: ''
        }},
    methods: {
        onLogout() {
            this.$store.dispatch('logout', true)
        },
        onHelp() {
            if(this.$store.getters.help.mode === 'url') {
                window.open(this.$store.getters.help.url,'_blank')
                return
            }else if(this.$store.getters.help.mode==='email') {
                const urlSearchParams = new URLSearchParams()
                if(this.$store.getters.help.cc) urlSearchParams.set('cc',this.$store.getters.help.cc)
                if(this.$store.getters.help.subject) urlSearchParams.set('subject',this.$store.getters.help.subject)
                const params = urlSearchParams.toString()

                window.open(`mailto:${this.$store.getters.help.email}${params ? `?${params}` : ''}`,'_blank')
            }
        },
        onSuggestion() {
            const urlSearchParams = new URLSearchParams()
            if(this.$store.getters.suggestion.cc) urlSearchParams.set('cc',this.$store.getters.suggestion.cc)
            if(this.$store.getters.suggestion.subject) urlSearchParams.set('subject',this.$store.getters.suggestion.subject)
            const params = urlSearchParams.toString()

            window.open(`mailto:${this.$store.getters.suggestion.email}${params ? `?${params}` : ''}`,'_blank')
        },
        getColor(user:any) {
            const c = window.localStorage.getItem('colors')
            const findColor = c ? JSON.parse(c) : colorsOrdered

            if (!user || !user?.username)
                return findColor[0]

            const code = (user.username.charCodeAt(0) +
                user.firstName.charCodeAt(2) +
                user.lastName.charCodeAt(2)) - 120

            if (isNaN(code) || code < 0)
                return findColor[0]

            return findColor[Math.min(code,findColor.length-1)]
        },
        userCanAccessPage(permissions: string[]) {
            return isFeatureAccessible(permissions, this.$store.getters.user.permissions)
        },
        async onSwitchClient(clientId: string) {
            try {
                await this.$store.dispatch('showPageLoading', true)
                await accountService.SwitchClient(clientId)
            } catch(e) {
                console.log(e)
            }
            await this.$store.dispatch('showPageLoading', false)
            await this.$store.dispatch('generateNewKey')
        }
    },
    async mounted() {
        // ===== Retrieve Logo and favicon (colors, styles, logo, and favicon) =====
        this.logo = `${process.env.VUE_APP_API_URL}/ThemeSettings/GetLogo?now=${Date.now()}`
        
        let favicon = document.querySelector('link[rel*=\'icon\']') as any
        if(favicon) {
            favicon.href = `${process.env.VUE_APP_API_URL}/ThemeSettings/GetFavicon?now=${Date.now()}`
        }
        // End of manipulating Themes

        this.routes = rootRoutes.filter(x=>typeof x.meta?.navBar === 'number' && this.userCanAccessPage(x.meta?.requiredPermissions || [])).map(x => ({
            ...x,
            path: x.path.replace(new RegExp('/:.+', 'g'), '')
        }))
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    watch:{
        $route(to) {
            this.group = this.routes.findIndex(x=>x.path === to.path)
        }
    }
})
