import { ILoggingService } from './LoggingService'
import axios from 'axios'
import {StackGeoLocation} from '@/types/LocationServiceType'

export default class LocationService {
    private readonly _logging: ILoggingService
    private readonly baseUrl = '/Location'

    constructor(logging: ILoggingService) {
        this._logging = logging
    }

    public async getLocations(addresses: string[]) : Promise<StackGeoLocation[]> {
        const url = `${this.baseUrl}/GetLocations`
        try {
            return (await axios.post(url, addresses)).data
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
        
    }

    public async upsertLocations(geocodedLocation: StackGeoLocation): Promise<void> {
        const url = `${this.baseUrl}/UpsertLocation`
        try {
            return (await axios.post(url, geocodedLocation)).data
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }
}