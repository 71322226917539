<template>
    <v-footer style="white-space:pre">
        {{appName}} {{version}} | <router-link
            to="/release-notes"
        >
            Release Notes
        </router-link> | © {{year}} <a :href="url">{{companyName}}</a> All rights reserved
    </v-footer>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
    data() {
        return {
            url: 'https://www.stackdynamicscorp.com',
            companyName: 'Stack Dynamics Corp.',
            appName: 'PropConn',
            version: process.env.VUE_APP_VERSION,
            year: new Intl.DateTimeFormat('en',{year:'numeric'}).format(new Date)
        }
    },
})
</script>
