import { dateAlphaFormat, dateFormat, dateTimeFormat, dateTimeSecondsFormat } from '@/constants'
import dayjs from 'dayjs'
import Vue from 'vue'
import { timeFormat } from './constants'


Vue.filter('formatDate', (date: Date) => {
    if (date == null || date == undefined) {
        return null
    }

    return dayjs(date).format(dateFormat)
})

Vue.filter('formatTimeOnly', (date: Date) => {
    if (date == null || date == undefined) {
        return null
    }

    return dayjs(date).format(timeFormat)
})

Vue.filter('formatTime', (time: string) => {
    if (time == null || time == undefined) {
        return null
    }

    return dayjs('2020-01-01 ' + time).format(timeFormat)
})

Vue.filter('formatDateTime', (date: Date) => {
    if (date == null || date == undefined) {
        return null
    }

    return dayjs(date).format(dateTimeFormat)
})

Vue.filter('formatDateTime_seconds', (date: Date) => {
    if (date == null || date == undefined) {
        return null
    }

    return dayjs(date).format(dateTimeSecondsFormat)
})

Vue.filter('formatDateAlpha', (date: Date) => {
    if (date == null || date == undefined) {
        return null
    }

    return dayjs(date).format(dateAlphaFormat)
})

Vue.filter('formatNumber', (num: number) => {
    if (num != null || num != undefined)
        return num.toLocaleString('en-US',{maximumFractionDigits:0})

    return null
})

Vue.filter('formatCurrency', (num: number) => {
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    if (num != null || num != undefined)
        return USDollar.format(num)

    return null
})

Vue.filter('formatCurrencyNoCents', (num: number) => {
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })

    if (num != null || num != undefined)
        return USDollar.format(num)

    return null
})

Vue.filter('formatBoolean', (val: boolean) => {
    return val? 'Yes': 'No'
})
