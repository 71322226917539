import { QuantumChartReport, QuantumChartReportApi } from '@/types/QuantumChartReportServiceType'
import { ILoggingService } from './LoggingService'
import axios from 'axios'

export default class QuantumChartReportService {
    private readonly _logging: ILoggingService
    private readonly baseUrl = '/QuantumChartReport'

    constructor(logging: ILoggingService) {
        this._logging = logging
    }

    public async getReports(source: string) :Promise<QuantumChartReport[]> {
        const url = `${this.baseUrl}/GetReports/${source}`
        try {
            const resp= (await axios.get<{ result: QuantumChartReportApi[]}>(url)).data.result
            return resp.map(element => {
                const copy  = {...element, settings: {}} as QuantumChartReport

                copy.settings = JSON.parse(element.settings)
                return copy
            })
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async upsertReport(form: QuantumChartReport) : Promise<void> {
        const url = `${this.baseUrl}/UpsertReport/`
        try {
            
            await axios.post(url, {
                ...form,
                settings: JSON.stringify(form.settings)
            })
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }

    public async deleteReport(form: QuantumChartReport) : Promise<void> {
        const url = `${this.baseUrl}/DeleteReport/`
        try {
            await axios.post(url, {
                ...form,
                settings: '{}'
            })
        } catch(error){
            this._logging.LogError(url, error)
            throw error
        }
    }
}