import { ILoggingService } from './LoggingService'
import { IApiService } from './ApiService'
import { Role, Permission } from '@/types/UserServiceType'
import { AxiosResponse } from 'axios'

export interface IUserService {
    getUserRoles(userId: string): Promise<Role[]>
    getRolePermissions(roleId: string) : Promise<Permission[]>
    getAvailableRoles(): Promise<Role[]>
    updateUser(user: {roles: string[], username: string, isActive: boolean}) : Promise<void>
    getAvailablePermissions(): Promise<Permission[]>
    upsertRole(role: Role): Promise<void>
    deleteRole(roleId: string): Promise<void>
}

export default class UserService implements IUserService {
    private readonly _logging: ILoggingService
    private readonly _api: IApiService
    private baseUrl = '/User'

    constructor(api: IApiService, logging: ILoggingService) {
        this._logging = logging
        this._api = api
    }

    public async getUserRoles(userName: string): Promise<Role[]> {
        const url = `${this.baseUrl}/GetUserRoles`
        const response = await this._api.post(url, { userName }) as AxiosResponse<{result: Role[]}>
        return response.data.result
    }

    public async getRolePermissions(id: string) : Promise<Permission[]> {
        const url = `${this.baseUrl}/GetRolePermissions`
        const response = await this._api.post(url, { id, Name: 'name', permissions: [] }) as AxiosResponse<{result: Permission[]}>
        return response.data.result
    }

    public async getAvailableRoles(): Promise<Role[]> {
        const url = `${this.baseUrl}/GetAvailableRoles`
        const response = await this._api.get(url) as AxiosResponse<{result: Role[]}>
        return response.data.result
    }

    public async updateUser(user: {roles: string[], username: string, isActive: boolean}): Promise<void> {
        const url = `${this.baseUrl}/UpdateUser`
        await this._api.post(url, user)
    }

    public async getAvailablePermissions(): Promise<Permission[]> {
        const url = `${this.baseUrl}/GetAvailablePermissions`
        const response = await this._api.get(url)  as AxiosResponse<{result: Permission[]}>
        return response.data.result
    }

    public async upsertRole(role: Role): Promise<void> {
        const url = `${this.baseUrl}/UpsertRole`
        await this._api.post(url, role)
    }


    public async deleteRole(id: string): Promise<void> {
        const url = `${this.baseUrl}/DeleteRole`
        await this._api.post(url, {id, Name: 'name', permissions: []} )
    }

}
