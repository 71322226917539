export interface ILoggingService {
    LogError(message: string, details: any): void
    LogMessage(message: string, details?: any): void
}

export default class LoggingService implements ILoggingService {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public LogError(message: string, details: any): void {
        console.log(message, details)
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public LogMessage(message: string, details: any): void {
        console.log(message, details)
    }
}
