var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","color":"white"}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"white--text",staticStyle:{"width":"100px"}},[(_vm.logo)?_c('img',{staticClass:"mr-4 pt-2",staticStyle:{"width":"100%"},attrs:{"alt":"logo","src":_vm.logo}}):_vm._e()]),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},_vm._l((_vm.routes),function(ref,i){
var path = ref.path;
var meta = ref.meta;
return _c('v-btn',{key:i,staticClass:"black-text text-capitalize v-btn-radius-exclude",attrs:{"text":"","x-large":"","active-class":"active-text primary","to":path}},[_vm._v(" "+_vm._s(meta && meta.text)+" ")])}),1),_c('v-spacer'),(_vm.user.clients.length > 1)?_c('v-autocomplete',{staticClass:"ma-auto",staticStyle:{"max-width":"370px"},attrs:{"items":_vm.user.clients,"item-text":"name","item-value":"id","filled":"","rounded":""},on:{"change":_vm.onSwitchClient},model:{value:(_vm.user.defaultClient),callback:function ($$v) {_vm.$set(_vm.user, "defaultClient", $$v)},expression:"user.defaultClient"}}):_vm._e(),_c('v-toolbar-items',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"ml-6",attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? 45 : 55,"color":_vm.getColor(_vm.user)}},on),[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.user.initial))])])]}}])},[_c('v-list',[(_vm.$store.getters.help.mode!=='off')?_c('v-list-item',{on:{"click":_vm.onHelp}},[_c('v-list-item-title',[_vm._v(" Need Help ")])],1):_vm._e(),(_vm.$store.getters.suggestion.email)?_c('v-list-item',{on:{"click":_vm.onSuggestion}},[_c('v-list-item-title',[_vm._v(" Suggest a Feature ")])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.onLogout}},[_c('v-list-item-title',[_vm._v(" Logout ")])],1)],1)],1)],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',{attrs:{"active-class":"white--text primary"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.routes),function(ref,i){
var path = ref.path;
var meta = ref.meta;
return _c('v-list-item',{key:i,attrs:{"disabled":_vm.group===i}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.$router.push(path)}}},[_vm._v(" "+_vm._s(meta && meta.text)+" ")])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }