





















































































import FooterBar from '@/components/FooterBar.vue'
import Forbidden from '@/components/Forbidden.vue'
import NavBar from '@/components/NavBar.vue'
import baseMixin from '@/mixins/baseMixin'
import mixins from 'vue-typed-mixins'
import ThemeRetriever from './components/ThemeRetriever.vue'

export default mixins(baseMixin).extend({
    name: 'App',
    components: {
        NavBar,
        FooterBar,
        Forbidden,
        ThemeRetriever
    },
    data() {
        return {
            fab: false,
            nivaUrl: null
        }
    },
    created() {
        if(process.env.VUE_APP_NIVA_URL) {
            this.nivaUrl = process.env.VUE_APP_NIVA_URL
        }
    }
})
