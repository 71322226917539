import { dateFormat, isoDateFormat } from '@/constants'
import { stackGmapService } from '@/services'
import { Page } from '@/types/enums'
import { GmapRecord } from '@/types/gmapMixinType'
import { GmapRecordSetting, StackGmapIconSetting } from '@/types/StackGmapServiceType'
import { GenerateGmapRecordParam } from '@/types/utilTypes'
import dayjs from 'dayjs'
export const roundQuarter = (num: number):number => parseFloat((Math.round(num * 4) / 4).toFixed(2))

export function sessionGet(key: string):string {
    const stringValue = window.sessionStorage.getItem(key)

    if(!stringValue) return ''

    const value = JSON.parse(stringValue)
    const expirationDate = new Date(value.expirationDate)
    if (expirationDate > new Date()) {
        return value.value
    } else {
        window.sessionStorage.removeItem(key)
        return ''
    }
}

// add into session
export function sessionSet(key: string, value: unknown, expirationInMin = 10):void {
    const expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin))
    const newValue = {
        value: value,
        expirationDate: expirationDate.toISOString()
    }
    window.sessionStorage.setItem(key, JSON.stringify(newValue))
}

export function shuffle(a: string[]):string[] {
    let j, x, i
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1))
        x = a[i]
        a[i] = a[j]
        a[j] = x
    }

    return a
}

export function getGoogleMapCountryByCode(countryCode: string): string {
    countryCode = (countryCode || '').toUpperCase()
    switch (countryCode) {
    case 'CA':
    case 'CAN':
        return 'CANADA'
    case 'US':
    case 'USA':
        return 'USA'
    case 'BEL':
        return 'BELGIUM'
    }

    return countryCode
}

export function getGmapAddress(location: {address: string, city:string, province: string, country: string, state?: string, propertyAddress?: string }): string {
    return  `${(location.address || location.propertyAddress || '').trim()}, ${(location.city || '').trim()}, ${(location.province || location.state || '').trim()}, ${getGoogleMapCountryByCode(location.country || 'CA').trim()}`
}


export function generateGmapRecord(param: GenerateGmapRecordParam): GmapRecord {    
    if(!param.page) {
        param.page = param.recordSetting.page
    }

    return {
        id: param.id,
        address: getGmapAddress(param.dynamicObj),
        header: param.dynamicObj[param.recordSetting.header],
        body: param.recordSetting.body?.map( rs => ({label: rs.alias, value: param.dynamicObj[rs.field] ?? ''})),
        ...(param.enableUrl && {url: {label: 'View Details', value: `/${param.page}/${param.id}`}}),
        iconFieldValue: param.dynamicObj[param.gmapIconSetting.iconField ?? ''],
        backgroundFieldValue: param.dynamicObj[param.gmapIconSetting.backgroundField ?? '']
    }

}

export function isCorrectDateFormat(date: string) : boolean {
    return dayjs(date, dateFormat, true).isValid()
}

export function toAppDateFormat(unknownDate: string): string {
    return unknownDate ? dayjs(unknownDate).format(dateFormat) : '' 
}

export function toIsoDateFormat(unknownDate: string) : string {
    return  unknownDate && isCorrectDateFormat(unknownDate) ? dayjs(unknownDate).format(isoDateFormat) : ''
}


export async function getGmapSettings(page:Page) : Promise<{gmapIconSetting: StackGmapIconSetting, gmapRecordSetting: GmapRecordSetting}>  {

    const gmapSettingsServiceFactory: Record<Page, { getGmapRecordSetting: () => Promise<GmapRecordSetting>, getIconSetting: () => Promise<StackGmapIconSetting>}>  = {
        [Page.PROPERTY] : {
            getGmapRecordSetting: stackGmapService.getGmapRecordSetting.bind(stackGmapService, Page.PROPERTY),
            getIconSetting: stackGmapService.getIconSetting.bind(stackGmapService, Page.PROPERTY)
        },
        [Page.MAP]: {
            getGmapRecordSetting: stackGmapService.getGmapRecordSetting.bind(stackGmapService, Page.MAP),
            getIconSetting: stackGmapService.getIconSetting.bind(stackGmapService, Page.MAP)
        },
        [Page.JOBS]: {
            getGmapRecordSetting: stackGmapService.getGmapRecordSetting.bind(stackGmapService, Page.JOBS),
            getIconSetting: stackGmapService.getIconSetting.bind(stackGmapService, Page.JOBS)
        },
        [Page.GLOBAL]: {
            getGmapRecordSetting: () => new Promise(()=>{return}),
            getIconSetting: () => new Promise(()=>{return})
        },
        [Page.PROPERTY_INFORMATION]: {
            getGmapRecordSetting: stackGmapService.getGmapRecordSetting.bind(stackGmapService, Page.PROPERTY),
            getIconSetting: stackGmapService.getIconSetting.bind(stackGmapService, Page.PROPERTY)
        },
        [Page.ACCOUNTING]: {
            getGmapRecordSetting: stackGmapService.getGmapRecordSetting.bind(stackGmapService, Page.ACCOUNTING),
            getIconSetting: stackGmapService.getIconSetting.bind(stackGmapService, Page.ACCOUNTING)
        }
    }


    const gmapSettingsService = gmapSettingsServiceFactory[page]
    const result = {
        gmapIconSetting: await gmapSettingsService.getIconSetting(),
        gmapRecordSetting: null as any
    }
    if(page && gmapSettingsService) {
        result.gmapRecordSetting = await gmapSettingsService.getGmapRecordSetting()
    } else {
        throw Error('Page variable has not been set')
    }

    return result

}


export async function initGmaps(this: {gmapIconSetting: StackGmapIconSetting, gmapRecordSetting: GmapRecordSetting, gmapOptions: { page: Page}, isMapConfigured: boolean}) : Promise<void> {
    try{
        if(!this?.gmapOptions?.page) {
            return
        } 
        const gmapSettings = await getGmapSettings(this.gmapOptions.page)

        this.gmapIconSetting = gmapSettings.gmapIconSetting
        this.gmapRecordSetting = gmapSettings.gmapRecordSetting
        this.isMapConfigured  = true
    } catch (e) {
        this.isMapConfigured  = false
        throw e
    }
}


export function isFeatureAccessible(permissions: string[], userPermissions: Record<string, boolean>): boolean {
    if(!permissions.length) return true
    const isAccessible = permissions.find( permission => userPermissions[permission])
    return !!isAccessible
}